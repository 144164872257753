import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Notification';

export default defineMessages({
  notification: {
    id: `${scope}.notifications`,
    defaultMessage: 'Thông báo',
  },
});
