import moment, { MomentInput } from 'moment';
import translations from 'translations';
import messages from './messages';

export const getDateText = (date?: MomentInput) => {
  const convertedDate = moment(date);

  if (!convertedDate.isValid()) {
    return null;
  }

  if (convertedDate.isSame(moment().startOf('day'), 'd')) {
    return `${convertedDate.format('HH:mm')} ${translations(messages.today)}`;
  }

  if (
    convertedDate.isSame(
      moment()
        .subtract(1, 'days')
        .startOf('day'),
      'd',
    )
  ) {
    return translations(messages.yesterday);
  }

  return moment(date).format('DD/MM');
};
